import React from 'react'
import { Container } from '@components'
import './about.scss'
import { Image } from '@elements'
import { useWindowSize } from '@hooks'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { graphql, useStaticQuery } from 'gatsby'
import TourDayLogo from '@images/tourday/tourday-logo.svg'

interface AboutProps {}

const About: React.FC<AboutProps> = () => {
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])

  const imagesQuery = graphql`
    query {
      computer: file(relativePath: { eq: "about/computer.webp" }) {
        ...ImageFluid868x530
      }
      itemGrid: file(relativePath: { eq: "about/item-grid.webp" }) {
        ...ImageFluid1884x2160
      }
    }
  `

  const imageData = useStaticQuery(imagesQuery)

  const computerImage = imageData?.computer?.childImageSharp?.fluid
  const itemGridImage = imageData?.itemGrid?.childImageSharp?.fluid

  return (
    <section className={'about'}>
      <div className={'about-first'}>
        <Container>
          <div className={'about-first-header'}>Everything in Golf You Need...or Just Want</div>
          <div className={'about-first-text'}>
            TourDay Golf is the premier destination to buy and sell new & like-new pre-owned golf clubs, golf
            accessories, and golf fashion. We provide a smooth, convenient, and personalized experience for both buyers
            and sellers.
          </div>
          <div className={'about-first-links'}>
            <AnchorLink to="/about#how-it-works">How it Works</AnchorLink>
            <AnchorLink to="/about#tourday-verified">TourDay Verified</AnchorLink>
            <AnchorLink to="/about#get-started">Getting Started</AnchorLink>
            <AnchorLink to="/about#our-catalog">Our Catalog</AnchorLink>
          </div>
        </Container>
      </div>
      <div id="how-it-works">
        <Container>
          <div className={'about-second'}>
            <div>
              <div className={'about-second-iframe-header'}>Learn About TourDay in 50 Seconds</div>
              <div className={isMobileRender ? 'about-second-iframe-wrapper-mobile' : 'about-second-iframe-wrapper'}>
                <iframe
                  src="https://share.synthesia.io/embeds/videos/1d207a7f-67c8-4fdc-832d-fa832322d284"
                  loading="lazy"
                  title="Synthesia video player - WELCOME TO TOURDAY GOLF"
                  allow="encrypted-media; fullscreen;"
                  className={'about-second-iframe-video'}
                ></iframe>
              </div>
            </div>
            <div className={'about-info'}>
              <div className={'about-info-header'} style={{ borderBottom: '2px solid #F7F7F7' }}>
                How it Works
              </div>
              <div className={'about-info-title'}>Find the Item You're Looking For</div>
              <div className={'about-info-text'}>
                TourDay has a catalog of thousands of golf products with new products added everyday -- including
                limited and items that are sold out elsewhere.
              </div>
              <div className={'about-info-title'}>Make an Offer</div>
              <div className={'about-info-text'}>
                Sellers on TourDay are looking to sell and invite you to make an offer.{' '}
                <span style={{ fontWeight: 500 }}>You are only charged if your Offer is accepted!</span> Choose an
                expiration date for your offer and cancel anytime. There are no fees for buyers.
              </div>
              <div className={'about-info-title'}>or... Buy Now</div>
              <div className={'about-info-text'}>
                Many items have Buy Now prices. Your chosen item will be mailed to you immediately after verification!
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div id="tourday-verified" className={'about-third'}>
        <div className={'about-third-image-wrapper'} id="clubsCovers">
          <Container>
            <div className={'about-third-image-info'}>
              <TourDayLogo className={'about-third-image-title-logo'} />
              <div className={'about-third-image-title'}>VERIFIED</div>
            </div>
          </Container>
        </div>
        <Container>
          <div className={'about-third-info'}>
            <div className={'about-third-info-header'}>TourDay Verified</div>
            <div className={'about-third-info-text'}>
              We Authenticate and We Guarantee Condition of every item. <br />
              <br />
              Every item goes through our Quality Control Team before shipping to the Golfer.
              <br />
              <br /> Once verified, all items will be packaged in a TourDay Box or Bag with a non-transferrable and
              non-replicable TourDay Holographic Sticker.
              <br />
              <br />
              <span style={{ fontWeight: 600 }}>Buy with Confidence.</span>
              <br />
              <br /> We have a 100% TourDay Verified Guarantee.
            </div>
          </div>
        </Container>
      </div>
      <div id="get-started" className={'about-fourth'}>
        <Container>
          <div className={'about-fourth-header'}>
            How to Get Started
            <div className={'about-fourth-header-text'}>
              It’s the ultimate golf shopping experience. The TourDay Golf Marketplace is designed for your convenience
              and shopping pleasure. We have carefully curated a golf marketplace experience like no other.
            </div>
          </div>
          <div className={'about-fourth-content'}>
            <div>
              <Image fluid={computerImage} />
            </div>
            <div>
              <div className={'about-fourth-content-title'}>Create an Account</div>
              <div className={'about-fourth-content-text'}>
                Build your bag, make unlimited offers, track the value of your collection, instant access to our
                seller’s portal, get notified about price drops, new releases, and much more.
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div id="our-catalog" className={'about-fifth'}>
        <Container>
          <div className={'about-fifth-content'}>
            <div className={'about-fifth-content-left'}>
              <div>
                <Image fluid={itemGridImage} />
              </div>
            </div>
            <div className={'about-fifth-content-right'}>
              <div className={'about-info-header'} style={{ borderBottom: '2px solid #ffffff' }}>
                Our Catalog
              </div>
              <div className={'about-info-title'}>Discover Thousands of Golf items</div>
              <div className={'about-info-text'}>
                Every item added to TourDay is reviewed and researched by our Golf Index Team. Only items that we have
                key data about and are confident we can verify are available on TourDay.
              </div>
              <div className={'about-info-title'}>Try Our Browsing Filters</div>
              <div className={'about-info-text'}>
                Because we collect tons of data points, you benefit because that means you can optimize your browsing
                experience.
              </div>
              <div className={'about-info-title'}>Limited & Sold Out Items</div>
              <div className={'about-info-text'}>Limited is our specialty.</div>
              <div className={'about-info-title'}>Golf Community Led</div>
              <div className={'about-info-text'}>
                By Golfers, For Golfers. If there is an item you’re looking for that’s not in our catalog, please let us
                know by email or by filling out the form once you’re logged in.
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  )
}
export default About
